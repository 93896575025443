<template>
  <div class="FakeGameActivity">
    <div>FakeGameActivity</div>
    <br>
    <div
      class="GameImage"
      :style="{ backgroundImage: `url(${gameImage})` }" />
    <div>{{ settings }}</div>
    <br>
    <div>
      <Button label="Generate random result" />
            &nbsp;
      <Button
        label="Submit fake result"
        @click="submit()" />
    </div>
  </div>
</template>

<script>
import Button from './Button';
const images = require.context('@/assets/images/memogenius/game/icon/', true, /\.(png|jpg)$/);

export default {
    components: { Button },
    props: {
        challenge: {
            type: Object,
            required: true
        }
    },
    computed: {
        settings() {
            return JSON.parse(this.challenge.settings);
        },
        gameImage() {
            return images(`./${this.settings.gameId}.png`);
        }
    },
    methods: {
        submit() {
            this.$store.dispatch('moduleSocial/createActivity', { challengeId: this.challenge.id });
        }
    }
};
</script>

<style scoped lang="scss">
.FakeGameActivity {
    position: relative;
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
}

.GameImage {
    width: 5em;
    height: 5em;

    background-size: contain;
}
</style>
