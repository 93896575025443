<template>
  <div class="ChallengePanel">
    <div><b>Challenge</b> {{ challenge.id }}</div>
    <br>
    <div>challengeType: {{ challenge.challengeType }}</div>
    <div>start: {{ challenge.start }}</div>

    <br>
    <div>
      <Button
        label="Delete"
        @click="deleteChallenge()" />
            &nbsp;
      <Button
        v-if="challenge.challengeType === 'memogeniusSingleSession'"
        label="Start Game Session"
        @click="startChallengeSession()" />
    </div>

    <br>
    <pre>
            {{ JSON.stringify(settings, null, '  ') }}
        </pre>
    <br>
    <FakeGameActivity
      v-if="
        challenge.challengeType === 'singleGameLevel' || challenge.challengeType === 'memogeniusSingleSession'
      "
      :challenge="challenge" />
    <div v-else>
      (No interaction for this type)
    </div>

    <br>
    <Leaderboard :leaderboard="leaderboard" />
  </div>
</template>

<script>
import gameHelper from '@/modules/games-shared/game-helper';
import Button from './Button';
import Leaderboard from './Leaderboard';
import FakeGameActivity from './FakeGameActivity';

export default {
    components: { Button, Leaderboard, FakeGameActivity },
    props: {
        challenge: {
            type: Object,
            required: true
        }
    },
    computed: {
        settings() {
            return JSON.parse(this.challenge.settings);
        },
        leaderboard() {
            return {
                entries: this.challenge.leaderboardsByChallengeIdList
            };
        }
    },
    methods: {
        deleteChallenge() {
            this.$store.dispatch('moduleSocial/deleteChallenge', { challengeId: this.challenge.id });
        },
        startChallengeSession() {
            gameHelper.createChallengeSession({
                challengeId: this.challenge.id,
                navigate: true,
                exitUrl: this.$route.path,
                resultUrl: this.$route.path
            });
        }
    }
};
</script>

<style scoped lang="scss">
.ChallengePanel {
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
}
</style>
