<template>
  <div class="ChallengeView">
    <div @click="back()">
      back to group
    </div>
    <br>

    <ChallengePanel
      v-if="challenge"
      :challenge="challenge" />
    <div v-else>
      (Did not find this challenge)
    </div>
  </div>
</template>

<script>
import ChallengePanel from '../components/ChallengePanel';

export default {
    components: { ChallengePanel },
    props: {
        groupId: {
            type: String,
            required: true
        },
        challengeId: {
            type: String,
            required: true
        }
    },
    computed: {
        group() {
            return this.$store.getters['moduleSocial/getGroup'](this.groupId);
        },
        challenge() {
            if (!this.group) {
                return;
            }
            return this.group.challengesByGroupIdList.find(c => c.id === this.challengeId);
        }
    },
    beforeCreate() {
        this.$store.dispatch('moduleSocial/refresh');
    },
    methods: {
        back() {
            this.$router.push(`/social/group/${this.groupId}`);
        }
    }
};
</script>

<style scoped lang="scss">
.ChallengeView {
    background-color: white;
    height: 100%;
    overflow-y: auto;
    padding: 1em;
}
</style>
